import { Directive, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Nullable } from '../../../shared/types/nullable.type';
import { Permission } from '../models/permission.model';
import { User } from '../models/user.model';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[authPermissions]',
  standalone: true,
})
export class PermissionDirective implements OnInit, OnDestroy {
  private readonly templateRef = inject(TemplateRef<unknown>);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly renderer = inject(Renderer2);
  private readonly authService = inject(AuthenticationService);

  @Input() authPermissionsAction: 'class' | 'hide' | 'attribute' = 'hide';
  @Input() authPermissionsClassNames = ['disabled'];
  @Input() authPermissionsAttribute: { key: string; value: string } = { key: 'disabled', value: 'true' };
  @Input() authPermissionsElse?: TemplateRef<unknown>;

  @Input() set authPermissions(permissions: Permission[] | undefined) {
    if (permissions) {
      this.permissions = permissions;

      this.checkPermission();
    }
  }

  private permissions!: Permission[];
  private user?: Nullable<User>;

  private readonly destroy$ = new Subject<void>();

  public ngOnInit(): void {
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;

      this.checkPermission();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkPermission(): void {
    if (!this.permissions || this.user?.hasPermissions(this.permissions)) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.authPermissionsElse) {
      this.viewContainerRef.createEmbeddedView(this.authPermissionsElse);
    } else {
      this.executeActionOnComponent();
    }
  }

  private executeActionOnComponent(): void {
    const view = this.viewContainerRef.createEmbeddedView(this.templateRef);

    switch (this.authPermissionsAction) {
      case 'class':
        this.authPermissionsClassNames.forEach((c) => this.renderer.addClass(view.rootNodes[0], c));
        break;
      case 'attribute':
        this.renderer.setAttribute(
          view.rootNodes[0],
          this.authPermissionsAttribute.key,
          this.authPermissionsAttribute.value
        );
        break;
      case 'hide':
      default:
        this.viewContainerRef.clear();
        break;
    }
  }
}
